import { PlatformLoadingView } from '@sequor/loading-screen';
import React, { Suspense } from 'react';

const SecurityRoot = React.lazy(() => import('@sequor/spa-security/dist/SecurityRoot'))

const SecurityStartup: React.FC = () => {
    return <Suspense fallback={<PlatformLoadingView />}>
        <SecurityRoot />
    </Suspense>
}

export default SecurityStartup