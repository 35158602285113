import { PlatformLoadingView } from '@sequor/loading-screen';
import React, { Suspense, useEffect, useState } from 'react';
import { getLogin } from '@sequor/security'
import { showNotificationError } from '@sequor/helius-notification'
import { useLanguageI18n } from '@sequor/internationalization'
import { Redirect } from 'react-router-dom';
import { LoadingOverlayContextProvider } from '@sequor/helius-loading-overlay';
import { DialogContextProvider } from '@sequor/helius-dialog';

const MainRoot = React.lazy(() => import('@sequor/spa-main/dist/MainRoot'))

const MainStartup: React.FC = () => {

    const languageI18n = useLanguageI18n()

    const [state, setState] = useState(<PlatformLoadingView />)

    useEffect(() => {
        getLogin()
            .then(({ success, data, messages }) => {
                if (success)
                    setState(<Suspense fallback={<PlatformLoadingView />}>
                        <DialogContextProvider
                            i18n={{
                                Cancel: languageI18n('cancel'),
                                Confirm: languageI18n('confirm'),
                                No: languageI18n('No'),
                                Ok: languageI18n('Ok'),
                                Yes: languageI18n('Yes')
                            }}>
                            <LoadingOverlayContextProvider>
                                <MainRoot loginReturn={data} />
                            </LoadingOverlayContextProvider>
                        </DialogContextProvider>
                    </Suspense>)
                else {
                    if (messages && messages.length >= 1) {
                        const { code, text } = messages[0]
                        showNotificationError(text !== '' ? text : languageI18n(code))
                    }

                    let search = ''

                    if (window.location.href) {
                        const url = new URL(window.location.href)

                        for (const [name, value] of Array.from(url.searchParams)) {
                            search += `${(search !== '' ? '&' : '')}${name}=${value}`
                        }

                        search = `?${search}`
                    }


                    setState(<Redirect to={{ pathname: '/logon', search }} />)
                }
            })
    }, [])

    return <React.Fragment>
        {state}
    </React.Fragment>
}

export default MainStartup

