import React from 'react';

const ViewVersion: React.FC = () => {
    const packageJson = require('../package.json')

    return <div>
        {packageJson.description}@{packageJson.version}
    </div>
}

export default ViewVersion