import React from 'react';
import { AppearanceContextProvider } from '@sequor/helius-appearance'
import { getAllPlatformThemeService } from './Services/PlatformThemeServices';
import { NotificationProvider } from '@sequor/helius-notification';
import { LanguageProvider } from '@sequor/internationalization'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import MainStartup from './Startup/MainStartup';
import SecurityStartup from './Startup/SecurityStartup';
import config from './core.lumen.ext.json';
import { AppConfiguration } from '@sequor/model';
import { PowerdBySequorLoadingView } from '@sequor/loading-screen';
import ViewVersion from './ViewVersion';

const App: React.FC = () => {

  const url = new URL(window.location.href)

  if (url.searchParams.has('viewversion')) {
    return <ViewVersion />
  }

  AppConfiguration.CoreConfiguration = config as any

  return <AppearanceContextProvider
    sources={[{
      type: 'Func',
      func: getAllPlatformThemeService
    }]}
    fallback={<PowerdBySequorLoadingView />}
    themeLoadFromBrowser={true}>

    <NotificationProvider>
      <LanguageProvider
        i18nExtLumen={config.i18n}>

        <BrowserRouter>
          <Switch>
            <Route path="/logon">
              <SecurityStartup />
            </Route>
            <Route exact path="/">
              <MainStartup />
            </Route>
            <Redirect from='*' to='/' />
          </Switch>
        </ BrowserRouter>

      </LanguageProvider>
    </NotificationProvider>
  </AppearanceContextProvider>
}

export default App
