import { IPlatformTheme } from "@sequor/helius-appearance";
import { createAxiosAndRoute } from '@sequor/axios'
import config from '../core.lumen.ext.json';

export const getAllPlatformThemeService = async (): Promise<IPlatformTheme[]> => {
    return new Promise<IPlatformTheme[]>(
        (resolve, reject) => {

            const { axiosInstance, route } = createAxiosAndRoute(config as any, 'PlatformTheme.GetAll')

            axiosInstance.getWithResponseStandard<IPlatformTheme[]>(route)
                .then(platformTheme => {
                    resolve(platformTheme)
                })
                .catch(err => {
                    reject(err)
                })
        }
    )
}

